<template>
    <v-app>
        <v-card width="300" outlined class="mx-auto my-auto" :loading="loading">
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light">{{title}}</span>
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-form ref="loginForm" v-model="infoValid" autocomplete="chrome-off">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-text-field  label="email" solo flat prepend-inner-icon="mdi-email" type="email" v-model="email" />
                            <v-text-field :type="!showPassword ? 'password' : 'text'" v-model="password" 
                                label="Password" solo flat prepend-inner-icon="mdi-lock" 
                                :append-icon="showPassword ? 'mdi-eye': 'mdi-eye-off'" 
                                @click:append="showPassword = !showPassword" />
                            </v-col>
                    </v-form>
                </v-row>

                <v-row justify="center"></v-row>
                
                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <v-alert type="success" dense v-if="loginStatus == 1">
                            {{ loginMsg }}
                        </v-alert>
                        <v-alert type="error" color="red" dense v-if="loginStatus == 0">
                            {{ loginMsg }}
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12" align="center">
                        <v-btn block color="info" :loading="btnLoading" @click="login" :disabled="!infoValid">Login</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-card-text class="mb-0 pb-0">
                        <p class="text-center font-weight grey--text overline mb-0">Power by Sigma</p>
                    </v-card-text>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>

<script>
import * as apiAuth from "@components/api/authpk/auth";
// import * as apiAuth from "@components/api/auth/auth";
import * as CryptoJS from 'crypto-js';
import { mapState } from "vuex";

export default {
    data: () => ({
        infoValid: true,
        title: "Sigma Position Keeper",
        email: '',
        password: '',
        redirectUrl: '',
        showPassword: false,     
        btnLoading: false,
        loading: false
    }), 
    mounted () {},
    computed: {
        ...mapState([
            'loginStatus',
            'loginMsg',
            'loginBtnLoading'
        ])
    },
    methods: {
        
        login() {
            let self = this;

            let params = { email: self.email, password: self.password };
            this.$store.state.jwtToken = "";

            this.btnLoading = true;
            this.loading = true;

            apiAuth.login(params).then(res => {

                let data = res.data;

                // get auth data
                self.$store.state.jwtToken = data.access_token;
                self.$store.state.assignUser = data.user;

                localStorage.setItem('bst', data.access_token);
                localStorage.setItem('bp', JSON.stringify(data.user.permission));
                // this.loading = false
                // this.btnLoading = false
                localStorage.setItem("globalNotificationAlert", "true");
                
                
                setTimeout(() => {
                    if (self.redirectUrl !== undefined) {
                        self.$router.push({name: 'Home'})
                        this.$store.state.loginStatus = undefined
                        this.loading = false
                        this.btnLoading = false
                    }
                }, 1000)

            }).catch(error => {
                this.loading = false
                this.btnLoading = false
            })
        },
    },
    created() {
       document.addEventListener("keyup", event => {
           if(event.key === 'Enter'){
               this.login()
           }
  })
    },
    mounted() {}
}
</script>