import axios from 'axios'
import store from "../../store/store.js";

let loadCounter = (function () {
    let count = 0;
    let loadingInstance;
    const show = () => {
        if (count > 0) {
            // show loading
            // store.state.snackbar = true;
            // store.state.snackmsg = "Logining...";
        } else {
            if (store.state.snackbar) {
                // this.$nextTick(() => {
                // close loading
                // store.state.snackbar = false;
                // })
            }
        }
    }
    return {
        increment() {
            count++;
            show();
        },
        decrement() {
            count--;
            show();
        }
    }
})();

// create an axios instance 
const service = axios.create({
    baseURL: APIURL,
    // baseURL: "https://user.bfjfunds.com.au/api/v1/",
    timeout: 30000,
    crossDomain: true
})

// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// request interceptor
service.interceptors.request.use(config => {
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded;charset=UTF-8";

    // let token = "Bearer " + localStorage.getItem('bst');
    // config['headers']['Authorization'] = token;
    
    store.state.loginBtnLoading = true;
    store.state.loginStatus = -1;
    loadCounter.increment();

    // console.log("Config: ", config);
    if (config.method.toLocaleLowerCase() == 'get') {
        // if get method add key to url
    }

    // if (config.method == 'get') {
    // config.data = qs.stringify(config.data)
    // }

    return config
}, error => {
    // do sth with request error
    console.log(error)
    loadCounter.decrement();
    Promise.reject(error)
})

service.interceptors.response.use(
    response => {

        store.state.snakmsg = response.data.message;
        store.state.snackcolor = 'success';

        store.state.loginStatus = 1;
        store.state.loginMsg = "Login Successfully, Redirecting...";
        loadCounter.decrement();
        store.state.loginBtnLoading = false;
        return response
    },
    error => {
        if (error.response) {
            loadCounter.decrement();
            localStorage.clear();
            store.state.loginBtnLoading = false;

            switch (error.response.status) {
                case 404:
                    store.state.snackcolor = "danger";
                    store.state.snakmsg = "Error 404, Page Not Found";
                    break;
                case 401:
                    // store.state.snackcolor = "danger";
                    // store.state.snakmsg = "Error 401, Auth Expired or Incorrect";
                    
                    store.state.loginStatus = 0;
                    store.state.loginMsg = "Login Failed, Username or Password Does not Match";
                    
                    if (window.location.href.indexOf("signin") == -1) {
                        window.location.href = "/#/signin";
                        // router.replace({
                        // path: '/#/login'
                        // query: { redirect: router.currentRoute.fullPath }
                        // })
                    }
                    break;

                case 500:
                    store.state.snackcolor = "danger";
                    store.state.snakmsg = "Error 500, Internal Server Error";
                    break;
                case 400:
                    store.state.snackcolor = "danger";
                    store.state.snakmsg = "Error 500, Error";
                    break;
            }
        }
    }
)

export default service