import request from '@utils/authpk/request'

export function login(query) {
    return request({
        url: 'auth/login',
        method: 'post',
        data: query
    })
}

export function register(query) {
    return request({
        url: 'auth/registration',
        method: 'post',
        data: query
    })
}
